import React from 'react'
import classes from './ProductsTableHeader.module.scss'

import polosa from '../../i/products/bg/polosa.jpg'
import krug from '../../i/products/bg/krug.jpg'
import krugBlin from '../../i/products/bg/krug-blin.jpg'
import kvadrat from '../../i/products/bg/kvadrat.jpg'
import pokovka from '../../i/products/bg/pokovka.jpg'
import utility from'./../../Layout/App/Container.module.scss'


class ProductsTableHeader extends React.Component{
    state = {
        productsHeaderData:[{
            category: 'Полоса',
            img: polosa,
            desc: `Полосы инструментальных сталей имеют высокие механические характеристики прочности, твердости и износоустойчивости. Их особенностью является устойчивость к коррозии, технологичность, а также возможность обработки различными способами. Они используются для изготовления деталей промышленного оборудования, материал которых должен обладать упругими свойствами, повышенной крепостью при изгибе и кручении. А также – это сверла, развертки, метчики, гребенки, фрезы. Вся продукция реализуется со склада в Никополе оптом и в розницу.`,
            shortDesc: `Полосы инструментальных сталей имеют высокие механические характеристики прочности, твердости и износоустойчивости.`
        },
        {
            category: 'Поковка',
            img: pokovka,
            desc: `Поковки инструментальных сталей имеют увеличенную вязкость, высокую прочность и твердость. Их особенностью является то, что в процессе ковки структура металла становиться мелкозернистой, а механические свойства повышаются. Благодаря чему детали произведенный с поковок выдерживают большие нагрузки и поэтому, они используются в множественных направлениях таких как: машиностроение, тяжелая металлургия, атомная энергетика, станкостроение и тд. К примеру, именно с этой стали изготовляют пресс формы, зубчатые колеса, штампы, цилиндры, втулки, шестерни, валы для машин и агрегатов и т.д.`,
            shortDesc: `Поковки инструментальных сталей имеют увеличенную вязкость, высокую прочность и твердость.`
        },{
            category: 'Квадрат',
            img: kvadrat,
            desc: `Квадраты инструментальных сталей изготовляются из легированной и углеродистой горячекатаной стали высокого качества. Их особенностями являются стойкость к деформации, большим температурам, пластичность, надежность и долговечность. Данная продукция используется в станкостроении, приборостроении, а также в производстве быстрорежущих инструментов (рубанков, фуганков и т.д.), и даже при крепеже железнодорожного полотна.`,
            shortDesc: `Квадраты инструментальных сталей изготовляются из легированной и углеродистой горячекатаной стали высокого качества.`
        },{
            category: 'Круг',
            img: krug,
            desc: `Круги инструментальных сталей имеют высокую износостойкость, антикоррозийные свойства, стойкость к механической деформации, а также способны сохранять твердость и долговечность при нагреве. Их производят в виде прутков круглого сечения. Они используются при производстве подшипников, быстрорежущих инструментов, холоднодеформированных штампов тяжелой нагрузки и пр.`,
            shortDesc: `Круги инструментальных сталей имеют высокую износостойкость, антикоррозийные свойства, стойкость к механической деформации, а также способны сохранять твердость и долговечность при нагреве.`
        },{
            category: 'Круг-блин',
            img: krugBlin,
            desc: `Круг-блин это изделие из инструментальных сталей имеющее характеристики идентичные кругу. Единственным отличием является их форма. Они производятся в виде металлического цилиндра с круглым отверстием посередине, после чего их разрезают на равные части в форме блина.`,
            shortDesc: `Круг-блин это изделие из инструментальных сталей имеющее характеристики идентичные кругу.`
        },
        
            
        ]
    }
//linear-gradient(0deg,rgba(255,0,150,0.3),rgba(255,0,150,0.3)),url(${item.img)
    render(){
        return(
            <>
                <div >
                        {this.state.productsHeaderData.map((item, index) =>{
                            if (item.category === this.props.category){
                                return(
                                    <div className={classes.products__table__header} key = {index} style = {{background:`linear-gradient(0deg,rgba(0,0,0,0.3),rgba(0,0,0,0.6)),url(${item.img})`, backgroundRepeat: 'no-repeat',backgroundPosition: 'center',backgroundSize: 'cover'}}>
                                        <div className = {utility.container}>
                                        <h2 data-aos="fade-right" data-aos-duration="1000">{item.category}</h2>
                                        <p data-aos="fade-right" data-aos-duration="1000">{window.innerWidth >= '650' ? item.desc : item.shortDesc}</p>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                </div>
            </>
        )
    }
} 
export default ProductsTableHeader